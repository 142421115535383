// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';
import Box from '@mui/material/Box';

// project import
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  actions as routeActions,
  types as routes
} from '../../../reducers/rotas.actions';
import { columns as columnsSimulacao } from './Columns';
import { toast } from 'react-toastify';
import {
  Button as Bt,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import Button from '../../../components/Button';
import {
  StarOutlined,
  EditOutlined,
  CalculatorOutlined,
  ApiOutlined
} from '@ant-design/icons';
import {
  atualizarBancoSelecionadoRequest,
  incluirPropostaIntegracaoRequest
} from '../../../reducers/simulacao.reducer';

const OportunidadesPage = () => {
  const { data: bancos } = useSelector((state) => state.dominios.listarBancos);
  const { loading: simulacaoLoading, data: dataSimulacao } = useSelector(
    (state) => state.simulacao
  );
  const { data, loading } = useSelector((state) => state.oportunidade);
  const situacaoIntegracao = useSelector((state) => state.simulacao.situacao);
  let rows = loading
    ? []
    : data.oportunidade.simulacoes.map((i) => {
        return {
          ...i,
          id: i.idSimulacao
        };
      });
  const { loading: simulacoesLoading } = useSelector(
    (state) => state.simulacao.list
  );

  useEffect(() => {
    if (!!dataSimulacao) {
      rows.map((r) => {
        if (r.idSimulacao === dataSimulacao.idSimulacao) {
          return {
            ...r,
            ...dataSimulacao
          };
        }
        return { ...r };
      });
    }
  }, [dataSimulacao]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const columns = columnsSimulacao({
    situacaoIntegracao,
    bancos
  });

  const handleRowClick = (params) => {
    const { idOportunidade } = params;
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, { id: idOportunidade })
    );
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    codigoOportunidadeBanco: false,
    dataHoraEnvioIntegracao: false,
    dataHoraRetornoIntegracao: false
  });

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.SIMULACAOBANCO, {
            idSimulacao: selectedRowData[0].idSimulacao,
            id: selectedRowData[0].idOportunidade
          })
        );
      }
    };

    const handleNew = () => {
      if (
        bancos?.filter((f) => f.flagSimulacao === 'S').length ===
        data?.oportunidade?.simulacoes?.length
      ) {
        toast.error('Todos os bancos já possuem simulação');
      } else {
        dispatch(
          routeActions.redirectTo(routes.NOVASIMULACAO, {
            id: data.oportunidade.idOportunidade
          })
        );
      }
    };

    const handleSelectBanco = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          atualizarBancoSelecionadoRequest({
            idSimulacao: selectedRowData[0].idSimulacao,
            idBanco: selectedRowData[0].idBanco,
            idOportunidade: selectedRowData[0].idOportunidade
          })
        );
        handleClose();
      }
    };

    const handleIntegracao = () => {
      dispatch(
        incluirPropostaIntegracaoRequest({
          idOportunidade: selectedRowData[0].idOportunidade,
          idSimulacao: selectedRowData[0].idSimulacao
        })
      );
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle variant="h3">
            Confirmação para o Banco escolhido
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja selecionar o banco{' '}
              {selectedRowData[0]?.banco?.nomeBanco}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={handleSelectBanco}
              variant="contained"
              isLoading={simulacaoLoading}
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Bt
            variant="text"
            onClick={handleClickOpen}
            disabled={selectedRows.size !== 1}
            startIcon={<StarOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              fontSize: '13px'
            }}
          >
            Selecionar Banco
          </Bt>
          <Bt
            variant="text"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              fontSize: '13px'
            }}
          >
            Editar
          </Bt>
          <Bt
            variant="text"
            disabled={
              bancos?.filter((f) => f.flagSimulacao === 'S').length ===
              data?.oportunidade?.simulacoes?.length
            }
            onClick={handleNew}
            startIcon={<CalculatorOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              fontSize: '13px'
            }}
          >
            Novo Banco/Simulação
          </Bt>
          <Bt
            variant="text"
            disabled={
              selectedRows.size === 0 ||
              (selectedRows.size === 1 &&
                selectedRowData[0]?.tipoSituacao !== 'P')
            }
            onClick={handleIntegracao}
            startIcon={<ApiOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              fontSize: '13px'
            }}
          >
            Incluir Proposta via API
          </Bt>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Box sx={{ marginTop: '5px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        density={'standard'}
        pageSize={10}
        pageSizeOptions={[10]}
        loading={loading || simulacoesLoading}
        toolbar={CustomToolbar}
        handleRowClick={handleRowClick}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        noLineText="Nenhuma simulação encontrada"
      />
    </Box>
  );
};

export default OportunidadesPage;
