import { getJwt, getRefreshToken, storeJwt } from './localStorage';

import axios from 'axios';

const baseAPI = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  timeout: 600000,
  withCredentials: false,
});

const exchangeAccessToken = async () => {
  const response = await authenticatedRequest({
    isResourceService: false,
    method: 'post',
    url: '/auth/token',
    body: {
      refreshToken: await getRefreshToken(),
    },
  });

  if (response.error) {
    throw response.error;
  }

  await storeJwt(response.data.jwt);
  return response.data.jwt;
};
const getAuthorizationHeaders = async (isResourceService) => {
  const accessToken = isResourceService
    ? await exchangeAccessToken()
    : await getJwt();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
export const unauthenticatedRequest = async ({
  url,
  method,
  queryParams,
  headers = {},
  body,
  isCep,
  ...other
}) => {
  return await _request({
    url,
    isCep,
    method,
    ...other,
    headers: {
      ...headers,
    },
    queryParams,
    body,
  });
};
export const authenticatedRequest = async ({
  isResourceService = true,
  url,
  method,
  queryParams,
  body,
  headers,
  base,
  ...other
}) => {
  return await _request({
    url,
    method,
    base,
    ...other,
    headers: {
      ...(await getAuthorizationHeaders(isResourceService)),
      ...headers,
    },
    queryParams,
    body,
  });
};
const _request = async ({
  url,
  base = baseAPI,
  method,
  headers = undefined,
  queryParams = {},
  body,
  isCep,
  ...other
}) => {
  url = isCep ? url : base + url;

  Object.keys(queryParams).forEach(
    (key) =>
      queryParams.hasOwnProperty.call(key) &&
      !queryParams[key] &&
      delete queryParams[key]
  );

  return instance.request({
    url,
    method,
    headers,
    ...other,
    params: queryParams,
    data: body,
  });

};

export default instance;

export const handleApiErrors = (response) => response;
