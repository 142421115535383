import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import {
  InfoCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

import { DataGrid } from 'components/grid/DataGrid';

import * as actions from 'reducers/atividades.reducer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import formatters from 'utils/formatters';
import Chip from 'components/Chip';
import useTheme from '@mui/system/useTheme';
import Loader from 'components/Loader';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { invalidarUsuario } from 'utils/invalidarUsuario';
import {
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '../../../../node_modules/@mui/material/index';

const Atividades = () => {
  const dispatch = useDispatch();

  const atividades = useSelector((state) => state.atividades.etapas.data);
  const loading = useSelector((state) => state.atividades.etapas.loading);
  const usuario = useSelector((state) => state.usuario);
  const openAddAtividade = useSelector((state) => state.atividades.openModal);

  const idOportunidade = useSelector(
    (state) => state.atividades.idOportunidade
  );

  const selectedEtapa = useSelector((state) => state.atividades.etapa);

  const atividadesPorEtapa = useSelector(
    (state) => state.atividades.atividadesPorEtapa.data
  );

  const loadingAtividadesPorEtapa = useSelector(
    (state) => state.atividades.atividadesPorEtapa.loading
  );

  const tiposSituacaoAtividade = useSelector(
    (state) => state.dominios.tiposSituacaoAtividade
  );

  const handleChangeSituacao = (value, idAtividade, situacaoAtual) => {
    if (value !== situacaoAtual) {
      dispatch(
        actions.atualizarAtividadeRequest({
          value: {
            idAtividade,
            tipoSituacao: value
          }
        })
      );
    }
  };

  const CustomToolbar = ({ apiRef }) => {
    const theme = useTheme();

    const tiposSituacaoAtividade = useSelector(
      (state) => state.dominios.tiposSituacaoAtividade
    );

    const [open, setOpen] = useState(false);

    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());
    const detalhes = selectedRowData[0]?.detalhes || {};

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const [selectedAtividade, setSelectedAtividade] = useState(false);

    const handleSelectEtapa = async (id) => {
      dispatch(actions.setEtapa(id));
      await dispatch(
        actions.listarAtividadesPorEtapaRequest({
          value: { idEtapa: id, idOportunidade }
        })
      );
    };

    const handleSaveAtividade = () => {
      dispatch(
        actions.criarAtividadeRequest({
          value: {
            idOportunidade,
            idEtapa: selectedEtapa,
            idAtividade: selectedAtividade
          }
        })
      );

      dispatch(actions.closeModal());
    };

    const etapas = atividades
      .map((doc) => ({
        idEtapa: doc.idEtapa,
        nome: doc.etapa
      }))
      .reduce((acc, current) => {
        if (!acc.some((item) => item.idEtapa === current.idEtapa)) {
          acc.push(current);
        }
        return acc;
      }, []);

    return (
      <>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle variant="h3">Detalhes da Atividade</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Etapa</Typography>
                <Typography variant="h6">{detalhes.nomeEtapa}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Atividade</Typography>
                <Typography variant="h6">{detalhes.nomeAtividade}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Situação</Typography>
                <Typography variant="h6">
                  {tiposSituacaoAtividade.find(
                    (el) => el.value === detalhes.tipoSituacao
                  )?.label ?? detalhes.tipoSituacao}
                </Typography>
              </Grid>
              {detalhes.dataInclusao && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5">Data Inclusão</Typography>
                    <Typography variant="h6">
                      {detalhes.dataInclusao
                        ? formatters.dates.short(
                            new Date(detalhes.dataInclusao)
                          )
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Incluído por</Typography>
                    <Typography variant="h6">
                      {detalhes.usuarioCriacao?.nome || 'N/A'}
                    </Typography>
                  </Grid>
                </>
              )}
              {detalhes.dataHoraAtuacao && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5">Data Início</Typography>
                    <Typography variant="h6">
                      {detalhes.dataHoraAtuacao
                        ? formatters.dates.withHours(
                            new Date(detalhes.dataHoraAtuacao)
                          )
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Atuado por</Typography>
                    <Typography variant="h6">
                      {detalhes.usuarioAtuacao?.nome || 'N/A'}
                    </Typography>
                  </Grid>
                </>
              )}
              {detalhes.dataHoraConclusao && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5">Data Conclusão</Typography>
                    <Typography variant="h6">
                      {detalhes.dataHoraConclusao
                        ? formatters.dates.withHours(
                            new Date(detalhes.dataHoraConclusao)
                          )
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Concluído por</Typography>
                    <Typography variant="h6">
                      {detalhes.usuarioConclusao?.nome || 'N/A'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="info"
            onClick={(e) => {
              dispatch(actions.setEtapa(0));
              dispatch(actions.openModal());
            }}
            startIcon={<PlusCircleOutlined />}
            sx={{
              color: `${
                invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                  ? theme.palette.grey.lighter
                  : theme.palette
              } !important`
            }}
          >
            Incluir Atividade
          </Button>
          <Dialog
            open={openAddAtividade}
            onClose={() => dispatch(actions.closeModal())}
          >
            {!loadingAtividadesPorEtapa ? (
              <>
                <DialogTitle>Adicionar Atividade</DialogTitle>
                <DialogContent
                  sx={{
                    minWidth: 400
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Stack spacing={0}>
                        <InputLabel htmlFor="participantes">Etapa</InputLabel>
                        <Select
                          id="participantes"
                          value={selectedEtapa}
                          onChange={(e) => handleSelectEtapa(e.target.value)}
                          fullWidth
                        >
                          <MenuItem value={null}></MenuItem>
                          {etapas.map((etapa) => {
                            return (
                              <MenuItem
                                key={etapa.idEtapa}
                                value={etapa.idEtapa}
                              >
                                {etapa.nome}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Stack>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Stack spacing={0}>
                        <InputLabel htmlFor="atividades">Atividades</InputLabel>
                        <Select
                          id="atividades"
                          value={selectedAtividade}
                          onChange={(e) => setSelectedAtividade(e.target.value)}
                          fullWidth
                        >
                          <MenuItem value={null}></MenuItem>
                          {atividadesPorEtapa.map((doc) => (
                            <MenuItem
                              key={doc.idAtividade}
                              value={doc.idAtividade}
                            >
                              {doc.nomeAtividade}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => dispatch(actions.closeModal())}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleSaveAtividade}
                    color="primary"
                    disabled={typeof selectedAtividade !== 'number'}
                  >
                    Adicionar
                  </Button>
                </DialogActions>
              </>
            ) : (
              <Loader />
            )}
          </Dialog>

          <Button
            variant="text"
            color="info"
            disabled={
              invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                ? true
                : selectedRows.size !== 1 ||
                  selectedRowData[0].tipoSituacao !== 'N'
            }
            onClick={() =>
              handleChangeSituacao(
                'E',
                selectedRowData[0].idAtividade,
                selectedRowData[0].tipoSituacao
              )
            }
            startIcon={<ClockCircleOutlined />}
            sx={{
              color: `${
                invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                  ? theme.palette.grey.lighter
                  : selectedRows.size !== 1 ||
                      selectedRowData[0].tipoSituacao !== 'N'
                    ? theme.palette.grey.lighter
                    : theme.palette.info.main
              } !important`
            }}
          >
            Em Andamento
          </Button>
          <Button
            variant="text"
            color="success"
            disabled={
              invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                ? true
                : selectedRows.size !== 1 ||
                  selectedRowData[0].tipoSituacao === 'C' ||
                  selectedRowData[0].tipoSituacao === 'N'
            }
            onClick={() =>
              handleChangeSituacao(
                'C',
                selectedRowData[0].idAtividade,
                selectedRowData[0].tipoSituacao
              )
            }
            startIcon={<CheckCircleOutlined />}
            sx={{
              color: `${
                invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                  ? theme.palette.grey.lighter
                  : selectedRows.size !== 1 ||
                      selectedRowData[0].tipoSituacao === 'C' ||
                      selectedRowData[0].tipoSituacao === 'N'
                    ? theme.palette.grey.lighter
                    : theme.palette.success.main
              } !important`
            }}
          >
            Concluída
          </Button>
          <Button
            variant="text"
            color="secondary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickOpen}
            startIcon={<InfoCircleOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: 'green'
            }}
          >
            Detalhes
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  const situacaoChips = {
    'Não Iniciada': { label: 'Não Iniciada', color: 'dark' },
    'Em Andamento': { label: 'Em Andamento', color: 'info' },
    Concluída: { label: 'Concluída', color: 'success' }
  };

  const uniqueEtapas = atividades
    .filter(
      (doc, index, self) =>
        index === self.findIndex((t) => t.etapa === doc.etapa)
    )
    .map((doc) => ({
      codigo: doc.etapa,
      nome: doc.etapa
    }));
  const uniqueAtividades = atividades
    .filter(
      (doc, index, self) =>
        index === self.findIndex((t) => t.atividade === doc.atividade)
    )
    .map((doc) => ({
      codigo: doc.atividade,
      nome: doc.atividade
    }))
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const columns = [
    {
      field: 'etapa',
      headerName: 'Etapa',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, [...uniqueEtapas])
        }
      ]
    },
    {
      field: 'atividade',
      headerName: 'Atividade',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, [...uniqueAtividades])
        }
      ]
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      renderCell: (params) => {
        const situacao = tiposSituacaoAtividade.find(
          (el) => el.value === params.value
        )?.label;

        const chipProps = situacaoChips[situacao] || {
          label: situacao,
          color: 'default'
        };
        return (
          <Chip
            size="small"
            variant="outlined"
            label={chipProps.label}
            color={chipProps.color}
            sx={{ fontSize: '11px', fontWeight: 700 }}
          />
        );
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              ...tiposSituacaoAtividade.map((el) => {
                return { codigo: el.value, nome: el.label };
              })
            ])
        }
      ]
    },
    {
      field: 'diasSla',
      headerName: 'SLA (dias)',
      flex: 1
    },
    {
      field: 'dataInicio',
      headerName: 'Início',
      flex: 1,
      valueFormatter: (value) =>
        `${value ? formatters.dates.withHours(new Date(value)) : ''}`,
      valueGetter: (value, row) => {
        return row.detalhes.dataHoraAtuacao;
      }
    },
    {
      field: 'dataPrevisaoConclusao',
      headerName: 'Previsão de Conclusão',
      flex: 1,
      valueFormatter: (value) =>
        `${value ? formatters.dates.revert(value) : ''}`
    },
    {
      field: 'dataConclusao',
      headerName: 'Conclusão',
      flex: 1,
      valueFormatter: (value) =>
        `${value ? formatters.dates.withHours(new Date(value)) : ''}`,
      valueGetter: (value, row) => {
        return row.detalhes.dataHoraConclusao;
      }
    }
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container sx={{ marginTop: '20px' }}>
      <Grid item xs={12}>
        <Box sx={{ marginTop: '10px' }}>
          <DataGrid
            rows={atividades ?? []}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            autoHeight={false}
            infiniteScroll
            getRowId={(row) => row.idAtividade}
            toolbar={CustomToolbar}
            hideFooterPagination
            noLineText="Nenhuma atividade cadastrada"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Atividades;
