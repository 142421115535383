import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import basicFlow, { genericErrorHandler } from './asyncHandler';
import { routeWatcher } from './rotas.saga';
import { authenticatedRequest, unauthenticatedRequest } from '../utils/api';
import {
  oportunidadesSuccess,
  oportunidadesRequest,
  oportunidadesFailure,
  oportunidadeSuccess,
  oportunidadeFailure,
  oportunidadeRequest,
  oportunidadeImovelIqSuccess,
  oportunidadeImovelIqFailure,
  oportunidadeImovelIqRequest,
  formatarOportunidade,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  editarOportunidadeRequest,
  editarOportunidadeSuccess,
  editarOportunidadeFailure,
  editarDataPrevistaRequest,
  editarDataPrevistaFailure,
  editarDataPrevistaSuccess,
  editarTemperaturaRequest,
  editarTemperaturaFailure,
  editarTemperaturaSuccess,
  editarSituacaoRequest,
  editarSituacaoFailure,
  editarSituacaoSuccess,
  obterEtapasRequest,
  obterEtapasFailure,
  obterEtapasSuccess,
  editarOportunidadeImovelIqRequest,
  editarOportunidadeImovelIqFailure,
  editarOportunidadeImovelIqSuccess,
  alterarMenuSelecionado,
  obterFunilDeVendasRequest,
  obterFunilDeVendasSuccess,
  obterFunilDeVendasFailure
} from '../reducers/oportunidade.reducer';
import {
  regionalRequest,
  parceiroRequest,
  usuariosParceirosRequest,
  usuariosRequest,
  operacaoRequest,
  etapasRequest,
  bancosRequest
} from '../reducers/dominios.reducer';
import { getPayload } from '../selectors/routes.selectors';
import { dominioSelectors } from 'selectors/index';
import { removeNonDigitsFromString } from 'utils/basic';
import { types as routes } from '../reducers/rotas.actions';
import formatters from '../utils/formatters';
import * as actions from '../reducers/compradores.reducer';
import * as vendedoresActions from '../reducers/vendedores.reducer';
import * as atividadesActions from '../reducers/atividades.reducer';
import * as fupActions from '../reducers/fup.reducer';
import * as documentosActions from '../reducers/documentos.reducer';

const buscarCep = basicFlow({
  actionGenerator: buscarCepRequest,
  actionFailure: buscarCepFailure,
  actionSuccess: buscarCepSuccess,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: 'get',
      isCep: true
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  }
});

const obterFunilApi = ({ value }) => {
  return authenticatedRequest({
    url: '/oportunidade/funil/obter',
    method: 'POST',
    body: { ids: value }
  });
};

const obterFunil = basicFlow({
  actionGenerator: obterFunilDeVendasRequest,
  actionFailure: obterFunilDeVendasFailure,
  actionSuccess: obterFunilDeVendasSuccess,
  api: obterFunilApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const listarOportunidadesApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/list',
    method: 'POST',
    body: values
  });
};

const listarOportunidades = basicFlow({
  actionGenerator: oportunidadesRequest,
  actionFailure: oportunidadesFailure,
  actionSuccess: oportunidadesSuccess,
  api: listarOportunidadesApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const mostrarOportunidadeApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/show/' + values,
    method: 'GET'
  });
};

const mostrarOportunidade = basicFlow({
  actionGenerator: oportunidadeRequest,
  actionFailure: oportunidadeFailure,
  actionSuccess: oportunidadeSuccess,
  api: mostrarOportunidadeApi,
  postSuccess: function* ({ response }) {
    const ufs = yield select(dominioSelectors.getUfs);
    const tiposImovel = yield select(dominioSelectors.getTiposImovel);
    const tiposUtilizacao = yield select(dominioSelectors.getTiposUtilizacao);

    const tipoImovel = tiposImovel.find(
      (el) => el.id === response.data.tipoImovel
    );

    const tipoUtilizacaoImovel = tiposUtilizacao.find(
      (el) => el.id === response.data.tipoUtilizacaoImovel
    );

    const uf = ufs.find((el) => el.codigo === response.data.uf);

    const formattedResponse = {
      ...response.data,
      tipoImovel,
      tipoUtilizacaoImovel,
      uf
    };

    yield put(formatarOportunidade(formattedResponse));
  },

  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const mostrarOportunidadeImovelIq = basicFlow({
  actionGenerator: oportunidadeImovelIqRequest,
  actionFailure: oportunidadeImovelIqFailure,
  actionSuccess: oportunidadeImovelIqSuccess,
  api: mostrarOportunidadeApi,
  postSuccess: function* ({ response }) {
    const ufs = yield select(dominioSelectors.getUfs);
    const tiposImovel = yield select(dominioSelectors.getTiposImovel);
    const tiposUtilizacao = yield select(dominioSelectors.getTiposUtilizacao);

    const tipoImovel = tiposImovel.find(
      (el) => el.id === response.data.tipoImovel
    );

    const tipoUtilizacaoImovel = tiposUtilizacao.find(
      (el) => el.id === response.data.tipoUtilizacaoImovel
    );

    const uf = ufs.find((el) => el.codigo === response.data.uf);

    const formattedResponse = {
      ...response.data,
      tipoImovel,
      tipoUtilizacaoImovel,
      uf
    };

    yield put(formatarOportunidade(formattedResponse));
  },

  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const editarOportunidadeApi = ({ value }) => {
  const payload = {
    ...value,
    dataHoraAlteracao: new Date().toISOString(),
    cep: removeNonDigitsFromString(value.cep),
    telefoneContatoAvaliacao: removeNonDigitsFromString(
      value.telefoneContatoAvaliacao
    )
  };

  if (typeof payload.uf === 'object') {
    payload.uf = payload?.uf?.codigo;
  }

  if (typeof payload.tipoImovel === 'object') {
    payload.tipoImovel = payload?.tipoImovel?.id ?? null;
  }

  if (typeof payload.tipoUtilizacaoImovel === 'object') {
    payload.tipoUtilizacaoImovel = payload?.tipoUtilizacaoImovel?.id ?? null;
  }

  return authenticatedRequest({
    url: '/oportunidade/update/' + payload.idOportunidade,
    method: 'PUT',
    body: payload
  });
};

const editarOportunidade = basicFlow({
  actionGenerator: editarOportunidadeRequest,
  actionFailure: editarOportunidadeFailure,
  actionSuccess: editarOportunidadeSuccess,
  api: editarOportunidadeApi,
  postSuccess: function* ({ response, values }) {
    if (values.value.isImovel) {
      yield toast.success('Imóvel editado com sucesso!');
    } else if (values.value.isIq) {
      yield toast.success('Interveniente Quitante editado com sucesso!');
    } else {
      yield toast.success('Oportunidade editada com sucesso!');
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const editarOportunidadeImovelIq = basicFlow({
  actionGenerator: editarOportunidadeImovelIqRequest,
  actionFailure: editarOportunidadeImovelIqFailure,
  actionSuccess: editarOportunidadeImovelIqSuccess,
  api: editarOportunidadeApi,
  postSuccess: function* ({ response, values }) {
    if (values.value.isImovel) {
      yield toast.success('Imóvel editado com sucesso!');
    } else if (values.value.isIq) {
      yield toast.success('Interveniente Quitante editado com sucesso!');
    } else {
      yield toast.success('Oportunidade editada com sucesso!');
    }
    yield put(oportunidadeImovelIqRequest(values.value.idOportunidade));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

function* oportunidadesRouteWatcher() {
  yield routeWatcher(routes.OPORTUNIDADES, function* () {
    yield put(regionalRequest());
    yield put(parceiroRequest({ tipo: 'filtro' }));
    yield put(usuariosParceirosRequest({ tipo: 'filtro' }));
    yield put(usuariosRequest());
    yield put(etapasRequest());
    yield put(oportunidadesRequest());
    yield put(bancosRequest());
    yield put(operacaoRequest());
  });
}

function* oportunidadeRouteWatcher() {
  yield routeWatcher(routes.OPORTUNIDADE, function* () {
    const { id } = yield select(getPayload);
    yield put(operacaoRequest());
    yield put(etapasRequest());
    yield put(usuariosRequest());
    yield put(oportunidadeRequest(id));
    yield put(oportunidadeImovelIqRequest(id));
    yield put(actions.listarCompradoresRequest(id));
    yield put(actions.adicionarOportunidade(id));
    yield put(vendedoresActions.listarVendedoresRequest(id));
    yield put(vendedoresActions.adicionarOportunidade(id));
    yield put(atividadesActions.listarEtapasRequest(id));
    yield put(atividadesActions.setEtapa(0));
    yield put(atividadesActions.closeModal());
    yield put(atividadesActions.adicionarOportunidade(id));
    yield put(fupActions.listarFupsRequest(id));
    yield put(fupActions.adicionarOportunidade(id));
    yield put(documentosActions.listarEntidadesRelacionadasRequest(id));
    yield put(bancosRequest());
    yield put(documentosActions.adicionarOportunidade(id));
    yield put(alterarMenuSelecionado(0));
  });
}

function* mostrarImovelRouteWatcher() {
  yield routeWatcher(
    [routes.IMOVEL, routes.INTERVENIENTE_QUITANTE],
    function* () {
      const { id } = yield select(getPayload);
      yield put(oportunidadeImovelIqRequest(id));
    }
  );
}

const editarDataPrevistaApi = (values) => {
  values.dataPrevistaEmissaoContrato = formatters.dates.revert(
    values.dataPrevistaEmissaoContrato
  );
  return authenticatedRequest({
    url: '/oportunidade/update/data-prevista-contrato/' + values.idOportunidade,
    method: 'PUT',
    body: { ...values }
  });
};

const editarDataPrevista = basicFlow({
  actionGenerator: editarDataPrevistaRequest,
  actionFailure: editarDataPrevistaFailure,
  actionSuccess: editarDataPrevistaSuccess,
  api: editarDataPrevistaApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const editarTemperaturaApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/update/temperatura/' + values.idOportunidade,
    method: 'PUT',
    body: { ...values }
  });
};

const editarTemperatura = basicFlow({
  actionGenerator: editarTemperaturaRequest,
  actionFailure: editarTemperaturaFailure,
  actionSuccess: editarTemperaturaSuccess,
  api: editarTemperaturaApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const editarSituacaoApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/update/situacao/' + values.idOportunidade,
    method: 'PUT',
    body: { ...values }
  });
};

const editarSituacao = basicFlow({
  actionGenerator: editarSituacaoRequest,
  actionFailure: editarSituacaoFailure,
  actionSuccess: editarSituacaoSuccess,
  api: editarSituacaoApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

const obterEtapasApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/obter-etapas/' + values,
    method: 'GET'
  });
};

const obterEtapas = basicFlow({
  actionGenerator: obterEtapasRequest,
  actionFailure: obterEtapasFailure,
  actionSuccess: obterEtapasSuccess,
  api: obterEtapasApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error: error });
  }
});

export const sagas = [
  listarOportunidades.watcher(),
  mostrarOportunidade.watcher(),
  mostrarOportunidadeImovelIq.watcher(),
  editarOportunidade.watcher(),
  buscarCep.watcher(),
  oportunidadesRouteWatcher(),
  oportunidadeRouteWatcher(),
  mostrarImovelRouteWatcher(),
  editarDataPrevista.watcher(),
  editarTemperatura.watcher(),
  editarSituacao.watcher(),
  obterEtapas.watcher(),
  editarOportunidadeImovelIq.watcher(),
  obterFunil.watcher()
];
