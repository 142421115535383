import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  idEtapa: null,
  etapas: {
    loading: false,
    error: false,
    data: []
  },
  atividades: {
    loading: false,
    error: false,
    data: []
  },
  atividadesPorEtapa: {
    loading: false,
    error: false,
    data: []
  },
  openModal: false,
  etapa: 0
};

const AtividadesSlice = createSlice({
  name: 'atividades',
  initialState,
  reducers: {
    listarEtapasRequest: (state) => {
      state.etapas.loading = true;
      state.etapas.error = null;
      state.atividades.data = [];
    },
    listarEtapasSuccess: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = false;
      state.etapas.data = action.payload.response.data;
    },
    listarEtapasFailure: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = action.payload;
    },
    listarAtividadesRequest: (state) => {
      state.atividades.loading = true;
      state.atividades.error = null;
    },
    listarAtividadesSuccess: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = false;
      state.atividades.data = action.payload.response.data;
    },
    listarAtividadesFailure: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = action.payload;
    },
    listarAtividadesPorEtapaRequest: (state) => {
      state.atividadesPorEtapa.loading = true;
      state.atividadesPorEtapa.error = null;
    },
    listarAtividadesPorEtapaSuccess: (state, action) => {
      state.atividadesPorEtapa.loading = false;
      state.atividadesPorEtapa.error = false;
      state.atividadesPorEtapa.data = action.payload.response.data;
    },
    listarAtividadesPorEtapaFailure: (state, action) => {
      state.atividadesPorEtapa.loading = false;
      state.atividadesPorEtapa.error = action.payload;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    atualizarAtividadeRequest: (state, action) => {
      state.atividades.loading = true;
      state.etapas.loading = true;
      state.atividades.error = null;
    },
    atualizarAtividadeSuccess: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = false;
    },
    atualizarAtividadeFailure: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = action.payload;
    },
    criarAtividadeRequest: (state, action) => {
      state.atividades.loading = true;
      state.etapas.loading = true;
      state.atividades.error = null;
    },
    criarAtividadeSuccess: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = false;
    },
    criarAtividadeFailure: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = action.payload;
    },
    adicionarEtapa: (state, action) => {
      state.idEtapa = Number(action.payload);
    },
    openModal: (state) => {
      state.openModal = true;
    },
    closeModal: (state) => {
      state.openModal = false;
    },
    setEtapa: (state, action) => {
      if (action.payload === 0) {
        state.etapa = 0;
        state.atividadesPorEtapa.data = [];
      } else {
        state.etapa = action.payload;
      }
    }
  }
});

export const {
  listarEtapasRequest,
  listarEtapasSuccess,
  listarEtapasFailure,
  listarAtividadesRequest,
  listarAtividadesSuccess,
  listarAtividadesFailure,
  listarAtividadesPorEtapaRequest,
  listarAtividadesPorEtapaSuccess,
  listarAtividadesPorEtapaFailure,
  adicionarOportunidade,
  atualizarAtividadeRequest,
  atualizarAtividadeSuccess,
  atualizarAtividadeFailure,
  adicionarEtapa,
  openModal,
  closeModal,
  setEtapa,
  criarAtividadeRequest,
  criarAtividadeSuccess,
  criarAtividadeFailure
} = AtividadesSlice.actions;

export default AtividadesSlice.reducer;
