import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import Autocomplete from 'components/form/Autocomplete';
import { Grid } from '@mui/material';

const Relacionamento = ({
  formProps,
  perfil,
  parceiros = [],
  usuariosParceiros = [],
  regionais = [],
  usuario
}) => {
  const [parceirosFiltrados, setParceirosFiltrados] = useState([]);

  const [usuariosParceirosFiltrados, setUsuariosParceirosFiltrados] = useState(
    []
  );

  const [regional, setRegional] = useState(usuario);

  const [parceiro, setParceiro] = useState(usuario);

  useEffect(() => {
    if (parceiro) {
      setUsuariosParceirosFiltrados(
        usuariosParceiros.filter(
          (item) => item.idParceiro === parceiro?.idParceiro
        )
      );
    } else {
      setUsuariosParceirosFiltrados([]);
      formProps.setValue('usuarioParceiro', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parceiro, usuariosParceiros]);

  useEffect(() => {
    if (!regional) {
      setParceirosFiltrados(parceiros);
      formProps.setValue('usuarioParceiro', null);
      formProps.setValue('parceiro', null);
    } else {
      setParceirosFiltrados(
        parceiros.filter((item) => item.idRegional === regional.idRegional)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regional, parceiros]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="regional">Regionais</InputLabel>
          <Autocomplete
            id="regional"
            name="regional"
            placeholder="Escolha a Regional"
            fullWidth
            labelField={'nomeRegional'}
            idField={'idRegional'}
            domain={regionais}
            formProps={formProps}
            onChange={(e) => {
              setRegional(e);
            }}
          />
        </Stack>
      </Grid>
      {['UP', 'PC'].includes(perfil) && (
        <Grid item xs={12} md={4}>
          <Stack spacing={0}>
            <InputLabel htmlFor="parceiro">Parceiro</InputLabel>
            <Autocomplete
              id="parceiro"
              name="parceiro"
              placeholder="Escolha o Parceiro"
              fullWidth
              labelField={'nomeParceiro'}
              idField={'idParceiro'}
              domain={parceirosFiltrados}
              formProps={formProps}
              onChange={(e) => setParceiro(e)}
            />
          </Stack>
        </Grid>
      )}
      {['UP'].includes(perfil) && (
        <Grid item xs={12} md={4}>
          <Stack spacing={0}>
            <InputLabel htmlFor="usuarioParceiro">Usuário Parceiro</InputLabel>
            <Autocomplete
              id="usuarioParceiro"
              name="usuarioParceiro"
              placeholder="Escolha o Usuário Parceiro"
              fullWidth
              labelField={'nomeUsuarioParceiro'}
              idField={'idUsuarioParceiro'}
              domain={usuariosParceirosFiltrados}
              formProps={formProps}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default Relacionamento;
