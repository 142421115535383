import formatters from '../../utils/formatters';
import Chip from '../../components/Chip';
import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';

const situacaoChips = {
  Ativa: { label: 'Ativa', color: 'info' },
  Cancelada: { label: 'Cancelada', color: 'error' },
  'Contrato Emitido': { label: 'Contrato Emitido', color: 'success' }
};

const temperaturaChips = {
  Fria: { label: 'Fria', color: 'info' },
  Morna: { label: 'Morna', color: 'warning' },
  Quente: { label: 'Quente', color: 'success' }
};

const opcoesFiltro = (props, itens) => {
  return (
    <>
      <InputLabel htmlFor="opcoes">Opções</InputLabel>
      <Select
        multiple
        value={props.item.value || []}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
        fullWidth
        id={'opcoes'}
        name={'opcoes'}
      >
        <MenuItem value="">
          <em>Nenhum</em>
        </MenuItem>
        {itens.map((option, index) => (
          <MenuItem key={option.codigo + index} value={option.codigo}>
            {option.nome}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const funcaoFiltro = (filterItem) => {
  if (!filterItem.value || filterItem.value.length === 0) {
    return null;
  }
  return (params) => {
    return filterItem.value.includes(params);
  };
};

const funcaoFiltroEtapa = (filterItem) => {
  if (!filterItem.value || filterItem.value.length === 0) {
    return null;
  }
  return (params) => {
    if (params.split(', ').length > 0) {
      return filterItem.value.some((i) => params.split(', ').includes(i));
    }

    return (params) => {
      return filterItem.value.includes(params);
    };
  };
};

export const columns = ({
  operacoes,
  temperatura,
  regionais,
  parceiros,
  usuariosParceiros,
  usuarios,
  bancos,
  etapas,
  usuario
}) => {
  return [
    {
      field: 'id',
      headerName: 'id',
      flex: 1
    },
    {
      field: 'comprador',
      headerName: 'Comprador',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row.participantes?.filter((i) => i.tipoQualificacao === 'CO' && i.compradorPrincipal === 'S')[0]?.nomeParticipante || ''}`;
      }
    },
    {
      field: 'parceiro',
      headerName: 'Parceiro',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.parceiro?.nomeParceiro || ''}`;
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              parceiros.data.map((i) => ({
                codigo: i.nomeParceiro,
                nome: i.nomeParceiro
              }))
            )
        }
      ]
    },
    {
      field: 'tipoOperacao',
      headerName: 'Tipo Operação',
      flex: 1,
      valueGetter: (value, row) => {
        return row?.operacao?.nomeOperacao;
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              operacoes.data.map((i) => ({
                codigo: i.nomeOperacao,
                nome: i.nomeOperacao
              }))
            )
        }
      ]
    },
    {
      field: 'cpfCnpj',
      headerName: 'CPF/CNPJ Comprador',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.participantes?.filter((i) => i?.tipoQualificacao === 'CO' && i?.compradorPrincipal === 'S')[0]?.cpfCnpj || ''}`;
      },
      valueFormatter: (value) =>
        `${formatters.strings.cpfCnpj(value, value?.length > 12 ? 'J' : 'F')}`
    },
    {
      field: 'valorTotalFinanciamento',
      headerName: 'Valor Financiado',
      type: 'number',
      flex: 1,
      valueFormatter: (value) => `R$ ${formatters.numbers.currency(value)}`
    },
    {
      field: 'regional',
      headerName: 'Regional',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.regional?.nomeRegional || ''}`;
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              regionais.data.map((i) => ({
                codigo: i.nomeRegional,
                nome: i.nomeRegional
              }))
            )
        }
      ]
    },
    {
      field: 'usuarioParceiro',
      headerName: 'Usuário Parceiro',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.usuarioParceiro?.nomeUsuarioParceiro || ''}`;
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              usuariosParceiros.data.map((i) => ({
                codigo: i.nomeUsuarioParceiro,
                nome: i.nomeUsuarioParceiro
              }))
            )
        }
      ]
    },
    {
      field: 'consultor',
      headerName: 'Consultor',
      flex: 1,
      type: 'number',
      valueGetter: (value, row) => {
        return row?.usuarioParceiro?.idUsuarioConsultor || '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              usuarios.data
                .filter((i) => i.perfil === 'CS')
                .map((i) => ({ codigo: i.id, nome: i.nome }))
            )
        }
      ],
      valueFormatter: (value) =>
        `${usuarios?.data?.find((i) => i.id === Number.parseInt(value))?.nome}`
    },
    {
      field: 'analista',
      headerName: 'Analista',
      flex: 1,
      type: 'number',
      valueGetter: (value, row) => {
        return row?.usuarioParceiro?.idUsuarioAnalista || '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              usuarios.data
                .filter((i) => i.perfil === 'AL')
                .map((i) => ({ codigo: i.id, nome: i.nome }))
            )
        }
      ],
      valueFormatter: (value) =>
        `${usuarios.data.find((i) => i.id === Number.parseInt(value))?.nome}`
    },
    {
      field: 'banco',
      headerName: 'Banco Escolhido',
      flex: 1,
      valueGetter: (value, row) => {
        return row?.bancoEscolhido?.nomeBanco ?? '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, bancos.map((i) => ({ codigo: i.nomeBanco, nome: i.nomeBanco })))
        }
      ]
    },
    {
      field: 'tipoTemperatura',
      headerName: 'Temperatura',
      flex: 1,
      valueGetter: (value) => {
        return !!value
          ? value === 'Q'
            ? 'Quente'
            : value === 'M'
              ? 'Morna'
              : 'Fria'
          : '';
      },
      renderCell: (params) => {
        const temperatura = params.value;
        const chipProps = temperaturaChips[temperatura] || {
          label: temperatura,
          color: 'default'
        };
        if (params.value) {
          return (
            <Chip
              size="small"
              variant="outlined"
              label={chipProps.label}
              color={chipProps.color}
              sx={{ fontSize: '11px', fontWeight: 700 }}
            />
          );
        } else {
          return '';
        }
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              temperatura.map((i) => ({ codigo: i.nome, nome: i.nome }))
            )
        }
      ]
    },
    {
      field: 'dataPrevistaEmissaoContrato',
      headerName: 'Previsão Emissão',
      type: 'date',
      flex: 1
    },
    {
      field: 'etapa',
      headerName: 'Etapa',
      flex: 1,
      valueGetter: (value, row) => {
        return [
          ...new Set(
            row.atividadesOportunidade
              ?.filter((i) => i.tipoSituacao === 'E')
              ?.map((i) => i.etapa.nomeEtapa)
          )
        ].join(', ');
      },
      filterOperators: [
        {
          label: 'contém',
          value: 'contains',
          getApplyFilterFn: (filterItem) => funcaoFiltroEtapa(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              etapas.data.map((i) => ({
                codigo: i.nomeEtapa,
                nome: i.nomeEtapa
              }))
            )
        }
      ]
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      valueGetter: (params) => {
        const value = params;
        return !!value
          ? value === 'A'
            ? 'Ativa'
            : value === 'C'
              ? 'Cancelada'
              : 'Contrato Emitido'
          : '';
      },
      renderCell: (params) => {
        const situacao = params.value;
        const chipProps = situacaoChips[situacao] || {
          label: situacao,
          color: 'default'
        };
        if (params.value) {
          return (
            <Chip
              size="small"
              variant="outlined"
              label={chipProps.label}
              color={chipProps.color}
              sx={{ fontSize: '11px', fontWeight: 700 }}
            />
          );
        } else {
          return '';
        }
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'Ativa', nome: 'Ativa' },
              { codigo: 'Cancelada', nome: 'Cancelada' },
              { codigo: 'Contrato Emitido', nome: 'Contrato Emitido' }
            ])
        }
      ]
    },
    {
      field: 'codigoOportunidade',
      headerName: 'Oportunidade',
      flex: 1
    },
    {
      field: 'dataOportunidade',
      headerName: 'Data',
      type: 'date',
      flex: 1
    }
  ];
};
