import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';

const DadosDeAcesso = ({ formProps }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataHoraUltimoAcesso">Último Acesso</InputLabel>
          <OutlinedInput
            disabled
            id="dataHoraUltimoAcesso"
            name="dataHoraUltimoAcesso"
            placeholder="-"
            fullWidth
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataUltimaTrocaSenha">
            Última Troca de Senha
          </InputLabel>
          <OutlinedInput
            disabled
            id="dataUltimaTrocaSenha"
            name="dataUltimaTrocaSenha"
            placeholder="-"
            fullWidth
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="quantidadeTentativaSenha">
            Quantidade de Tentativas de Senha
          </InputLabel>
          <OutlinedInput
            disabled
            id="quantidadeTentativaSenha"
            name="quantidadeTentativaSenha"
            placeholder="0"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDeAcesso;
