import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/atividades.reducer';
import * as oportunidadeActions from 'reducers/oportunidade.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { types as routes } from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarEtapas = (values) => {
  return authenticatedRequest({
    url: `/atividades/list/etapas/${values}`,
    method: 'get'
  });
};

const listarEtapas = basicFlow({
  actionGenerator: actions.listarEtapasRequest,
  actionFailure: actions.listarEtapasFailure,
  actionSuccess: actions.listarEtapasSuccess,
  api: apiListarEtapas,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarAtividades = ({ value }) => {
  return authenticatedRequest({
    url: `/atividades/list/atividades?idOportunidade=${value.idOportunidade}&idEtapa=${value.idEtapa}`,
    method: 'get'
  });
};

const listarAtividades = basicFlow({
  actionGenerator: actions.listarAtividadesRequest,
  actionFailure: actions.listarAtividadesFailure,
  actionSuccess: actions.listarAtividadesSuccess,
  api: apiListarAtividades,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarAtividadesPorEtapa = ({ value }) => {
  return authenticatedRequest({
    url: `/atividades/list/atividades-por-etapa?idEtapa=${value.idEtapa}&idOportunidade=${value.idOportunidade}`,
    method: 'get'
  });
};

const listarAtividadesPorEtapa = basicFlow({
  actionGenerator: actions.listarAtividadesPorEtapaRequest,
  actionFailure: actions.listarAtividadesPorEtapaFailure,
  actionSuccess: actions.listarAtividadesPorEtapaSuccess,
  api: apiListarAtividadesPorEtapa,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiAtualizarAtividade = ({ value }) => {
  return authenticatedRequest({
    url: `/atividades/update/${value.idAtividade}`,
    method: 'put',
    body: value
  });
};

const apiCriarAtividade = ({ value }) => {
  return authenticatedRequest({
    url: `/atividades/add`,
    method: 'post',
    body: value
  });
};

const atualizarAtividades = basicFlow({
  actionGenerator: actions.atualizarAtividadeRequest,
  actionFailure: actions.atualizarAtividadeFailure,
  actionSuccess: actions.atualizarAtividadeSuccess,
  api: apiAtualizarAtividade,
  postSuccess: function* (value) {
    toast.success('Atividade atualizada com sucesso.');
    const { id } = yield select(getPayload);
    yield put(actions.listarEtapasRequest(id));
    yield put(actions.adicionarEtapa(value.response.data.idEtapa));
    yield put(oportunidadeActions.obterEtapasRequest(id));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const criarAtividades = basicFlow({
  actionGenerator: actions.criarAtividadeRequest,
  actionFailure: actions.criarAtividadeFailure,
  actionSuccess: actions.criarAtividadeSuccess,
  api: apiCriarAtividade,
  postSuccess: function* (value) {
    toast.success('Atividade incluída com sucesso.');
    const { id } = yield select(getPayload);
    yield put(actions.listarEtapasRequest(id));
    yield put(actions.adicionarEtapa(value.response.data.idEtapa));
    yield put(oportunidadeActions.obterEtapasRequest(id));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarEtapasRouteWatcher() {
  yield routeWatcher(routes.ATIVIDADES, function* () {
    const { id } = yield select(getPayload);
    if (!id) return;
    yield put(actions.listarEtapasRequest(id));
    yield put(actions.adicionarOportunidade(id));
  });
}

export const sagas = [
  listarEtapas.watcher(),
  listarAtividades.watcher(),
  criarAtividades.watcher(),
  atualizarAtividades.watcher(),
  listarAtividadesPorEtapa.watcher(),
  listarEtapasRouteWatcher()
];
